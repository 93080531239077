import { TariffHomeInternet } from '@/store/api/tariff-home-internet/tariff-home-internet.type';
import { BUILDING_TYPE, HOME_INTERNET_SERVICE_TYPE } from '@/store/api/service-home-internet/service-home-internet.type';
import { DIY_TARIFF_NAME } from '@/shared/constants/diy';
import { formatPrice } from '@/shared/lib/utils';
import { 
  HomeInternetPrice, 
  MobilePricesCounterArgs, 
  PromotionDescriptionMakerArgs, 
  ShpdPriceCounterArgs, 
  ShpdPrices, 
  ShpdPricesBlock 
} from '.';

export const homeInternetPriceCounter = (tariff?: TariffHomeInternet, evpatoria?: boolean): HomeInternetPrice => {
  const mobile = tariff?.tariff_mobiles?.data?.[0]?.attributes;

  const evpatoria_price =
    (tariff?.default_services?.data.reduce((acc, tariff) => {
      return (acc += tariff?.attributes?.prices?.evpatoria_price || 0);
    }, 0) || 0) + (mobile?.shpd_prices?.evpatoria_price || 0);

  const default_price =
    (tariff?.default_services?.data.reduce((acc, tariff) => {
      return (acc += tariff?.attributes?.prices?.[mobile ? 'convergent_price' : 'mono_price'] || 0);
    }, 0) || 0) + (mobile?.shpd_prices?.price_apartment || 0);

  const home_price =
    (tariff?.shpd_services?.data.find(({ attributes }) => attributes.building_type === BUILDING_TYPE.HOUSE)?.attributes
      ?.prices?.[mobile ? 'convergent_price' : 'mono_price'] || 0) + (mobile?.shpd_prices?.price_house || 0);

  const apartment_price =
    (tariff?.shpd_services?.data.find(({ attributes }) => attributes.building_type === BUILDING_TYPE.APARTMENT)
      ?.attributes?.prices?.[mobile ? 'convergent_price' : 'mono_price'] || 0) +
    (mobile?.shpd_prices?.price_apartment || 0);

  const hiPromotionPrice = tariff?.default_services?.data?.reduce((acc, tariff) => {
    return (acc += tariff?.attributes?.prices?.promotion_price || 0);
  }, 0);

  const affects_promotion = mobile?.affects_shpd_promotion?.data?.some(({ attributes }) =>
    tariff?.default_services?.data.some((service) => attributes.shpd_id === service.attributes.shpd_id),
  );

  const promotion_price = affects_promotion
    ? undefined
    : (mobile?.shpd_prices?.promotion_price || 0) + (hiPromotionPrice || 0);

  const promotion_description = tariff?.default_services?.data?.find(
    ({ attributes }) => attributes.service_type === HOME_INTERNET_SERVICE_TYPE.SPEED,
  )?.attributes?.promotion_description;
  const promotion_period = tariff?.default_services?.data?.find(
    ({ attributes }) => attributes.service_type === HOME_INTERNET_SERVICE_TYPE.SPEED,
  )?.attributes?.promotion_period;

  const promotion = promotion_price && !evpatoria ?
    {
      promotion_price,
      promotion_description,
      promotion_period,
    } :
    undefined;

  const special = evpatoria || tariff?.name === DIY_TARIFF_NAME;

  return {
    default_price: evpatoria ? evpatoria_price : default_price,
    promotion,
    home_price: special ? undefined : home_price,
    apartment_price: special ? undefined : apartment_price,
  };
};

export const mobilePricesCounter = ({
  mobileTariff,
  isEvpatoria,
  mobileTouchedServices,
  speedId,
  buildingType,
  isConvergent,
}: MobilePricesCounterArgs): ShpdPricesBlock => {
  const basePrice = isConvergent ?
    mobileTariff?.shpd_prices?.[
      isEvpatoria ?
        'evpatoria_price' :
        buildingType === BUILDING_TYPE.HOUSE ?
          'price_house' :
          'price_apartment'
    ] || 0 :
    mobileTariff?.price || 0;

  /**
   * итоговая цена услуг
   */
  const servicesPrice =
    mobileTouchedServices?.reduce((acc, { added, servicePrice }) => (acc += added ? servicePrice : 0), 0) || 0; 

  /**
   * цена услуг без акций
   */
  const servicesPriceWithoutPromotions = mobileTouchedServices?.reduce((acc, { added, servicePrice, oldPrice }) => {
    const currentPrice = oldPrice || servicePrice;

    return acc+= added ? currentPrice : 0;
  }, 0) || 0;

  /**
   * есть ли акции в услугах
   */
  const serviceHasPromotion = servicesPriceWithoutPromotions > servicesPrice;

  /**
   * цена МС без акции + услуги без акций
  */
  const totalPrice = basePrice + servicesPriceWithoutPromotions;

  /**
   * есть ли акция у тарифа МС
  */
  const basePriceHasPromotion = Boolean(mobileTariff?.promotion_price);

  /**
   * цена МС без акции + цена услуг без акций
  */
  const promotionBasePrice = (basePriceHasPromotion || serviceHasPromotion) ? 
    ((mobileTariff?.price || 0) + servicesPriceWithoutPromotions) : null;

  /**
   * итоговая цена МС + итоговая цена услуг
  */
  const promotionTotalPrice = (basePriceHasPromotion || serviceHasPromotion) ? 
    (mobileTariff?.promotion_price || mobileTariff?.price || 0) + servicesPrice
    : null;

  /**
   * ломается ли акция при добавлении ДИ
  */
  const withoutPromotions = mobileTariff?.affects_shpd_promotion?.some((item) => item === speedId);

  /**
   * базовая цена с ДИ 
  */
  const promotionBasePriceWithInternet = withoutPromotions || isEvpatoria ? basePrice : mobileTariff?.shpd_prices?.promotion_price;

  /**
   * итоговая цена с ДИ
  */
  const promotionTotalPriceWithInternet = valueIsNumber(promotionBasePriceWithInternet) ? (promotionBasePriceWithInternet || 0) + servicesPrice : null;

  return {
    basePrice,
    totalPrice,
    promotionBasePrice: isConvergent ? promotionBasePriceWithInternet : promotionBasePrice,
    promotionTotalPrice: isConvergent ? promotionTotalPriceWithInternet : promotionTotalPrice,
  };
};

export const shpdPricesCounter = ({
  speed,
  tv,
  homeInternetTouchedServices,
  mobileTariff,
  mobileTouchedServices,
  isEvpatoria,
  isConvergent,
  buildingType,
}: ShpdPriceCounterArgs): ShpdPrices => {
  const speedPrice = speed.prices?.[isEvpatoria ? 'evpatoria_price' : isConvergent ? 'convergent_price' : 'mono_price'];
  const tvPrice = tv.prices?.[isEvpatoria ? 'evpatoria_price' : isConvergent ? 'convergent_price' : 'mono_price'];

  const servicesPrice = homeInternetTouchedServices?.reduce(
    (acc, { added, prices }) =>
      (acc += added
        ? prices?.[isEvpatoria ? 'evpatoria_price' : isConvergent ? 'convergent_price' : 'mono_price'] || 0
        : 0),
    0,
  );

  const {
    basePrice: mobileBasePrice,
    totalPrice: mobileTotalPrice,
    promotionBasePrice: promotionBasePriceMobile,
    promotionTotalPrice: promotionTotalPriceMobile,
  } = mobilePricesCounter({
    mobileTariff,
    isEvpatoria,
    mobileTouchedServices,
    speedId: speed.shpd_id,
    buildingType,
    isConvergent,
  });

  const totalBasketPrice =
    (speedPrice || 0) + (tvPrice || 0) + servicesPrice + (isConvergent ? mobileTotalPrice || 0 : 0);
  const basePrice = (speedPrice || 0) + (tvPrice || 0);
  const totalPrice = basePrice + servicesPrice;
  const affectSpeedPromotion = mobileTariff?.affects_shpd_promotion?.some((item) => item === speed.shpd_id);
  const affectTvPromotion = mobileTariff?.affects_shpd_promotion?.some((item) => item === tv.shpd_id);

  const speedPricePromotion =
    affectSpeedPromotion || isEvpatoria
      ? speedPrice
      : valueIsNumber(speed.prices?.promotion_price)
        ? speed.prices?.promotion_price
        : speedPrice;
  const tvPricePromotion =
    affectTvPromotion || isEvpatoria
      ? tvPrice
      : valueIsNumber(tv.prices?.promotion_price)
        ? tv.prices?.promotion_price
        : tvPrice;
  const servicesPricePromotion = homeInternetTouchedServices.reduce((acc, { added, prices }) => {
    if (added) {
      acc += valueIsNumber(prices?.promotion_price)
        ? prices?.promotion_price || 0
        : prices?.[isConvergent ? 'convergent_price' : 'mono_price'] || 0;
    }

    return acc;
  }, 0);
  const evpatoriaTotalBasketPriceWithPromotion =
    (speedPrice || 0) + (tvPrice || 0) + servicesPricePromotion + (isConvergent ? mobileTotalPrice || 0 : 0);
  const totalBasketPriceWithPromotion =
    (speedPricePromotion || 0) +
    (tvPricePromotion || 0) +
    servicesPricePromotion +
    (isConvergent ? promotionTotalPriceMobile || 0 : 0);

  const promotionBasePrice = (speedPricePromotion || 0) + (tvPricePromotion || 0);
  const promotionTotalPrice = (promotionBasePrice || 0) + servicesPricePromotion;

  return {
    totalBasketPrice,
    totalBasketPriceWithPromotion: isEvpatoria ? evpatoriaTotalBasketPriceWithPromotion : totalBasketPriceWithPromotion,
    basePrice,
    totalPrice,
    promotionBasePrice,
    promotionTotalPrice,
    mobilePrices: {
      basePrice: mobileBasePrice,
      totalPrice: mobileTotalPrice,
      promotionBasePrice: promotionBasePriceMobile,
      promotionTotalPrice: promotionTotalPriceMobile,
    },
  };
};

export const valueIsNumber = (val: number | null | undefined) => {
  if (typeof val === 'number') return true;

  return false;
};

export const promotionDescriptionMaker = ({
  promotionPrice,
  promotionPeriod,
  price,
  text,
  buildingType,
}: PromotionDescriptionMakerArgs) => {
  return (
    <div>
      {text && text} <br />
      {promotionPeriod} = {formatPrice(promotionPrice)} /мес. Далее {formatPrice(price)} /мес
      для {buildingType === BUILDING_TYPE.APARTMENT ? 'квартир' : 'частных домов'}.
    </div>
  );
};
